<template>
  <v-card v-if="props.item.finances && props.item.finances.length > 0" dark>
    <v-data-table dense :headers="headersFinances" :items="props.item.finances" class="elevation-0" hide-actions >
      <template v-slot:headers="hprops">
        <tr style="height: 40px;">
          <th class="hidden-xs-only hidden-sm-only" v-for="header in hprops.headers" :key="header.text" style="text-align: center !important; margin: 0 !important; padding: 0px !important;">
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:items="iprops">
        <tr>
          <td class="justify-center">
            <strong class="finance_status parcel">{{iprops.item.number_this}}/{{iprops.item.number_total}}</strong>
          </td> 
          <td class="justify-center">
            <strong>{{iprops.item.date_validity_formated}}</strong>
          </td> 
          <td class="justify-center">
            <strong v-if="iprops.item.finance && iprops.item.finance.date_receive_format && iprops.item.finance.status == 2">{{iprops.item.finance.date_receive_format}}</strong>
            <span v-else> - </span>
          </td>  
          <td class="justify-center">
            <strong style="font-size: 16px;">{{iprops.item.amount | money}}</strong>
          </td>
          <td class="justify-center">
            <span v-if="iprops.item.finance">
              <strong v-if="iprops.item.finance.status == 0" class="finance_status canceled">Cancelado</strong>
              <strong v-else-if="iprops.item.finance.status == 1" class="finance_status unpaid">Aguardando</strong>
              <strong v-else-if="iprops.item.finance.status == 2" class="finance_status paid">Pago</strong>
            </span>
            <strong v-else-if="iprops.item.stage == 3" class="finance_status canceled">Cancelado</strong>
            <strong v-else class="finance_status unpaid">Aguardando</strong>
          </td>
          <td class="justify-center">
            {{iprops.item.description || ' - '}}
          </td>
          <td class="justify-center">
            <div v-if="iprops.item.finance_form_payment_id && iprops.item.form_payment">
              <v-icon :color="iprops.item.form_payment.color">{{iprops.item.form_payment.icon}}</v-icon> <br>
              <span>{{iprops.item.form_payment.name}}</span>
            </div>
            <div v-else>Indefinido</div>
          </td>
          <td class="justify-center">
            <v-btn v-if="iprops.item.finance" :disabled="iprops.item.finance.status != 1" small outline color="green" @click="$WiEditDialog({wiConfig: 'finance-out', data: iprops.item.finance, onSubmit: config.refresh})">
              Pagar
            </v-btn>
            <v-btn v-else small outline color="green" @click="$WiEditDialog({wiConfig: 'finance-out', data: getNewFinanceData(iprops.item), onSubmit: config.refresh})">
              Lançar no Financeiro
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>     
</template>
<script>
  export default {
    name: 'BusinessPersonExpand',
    data () {
      return {
        headersFinances: [
          { align: 'center', text: 'Parcela', value: 'number_this', sortable: false },
          { align: 'center', text: 'Vencimento', value: 'nome_produto', sortable: false },
          { align: 'center', text: 'Pagamento', value: 'date_receive', sortable: false },
          { align: 'center', text: 'Valor', value: 'desc_negocio_produto', sortable: false },
          { align: 'center', text: 'Status', value: 'qtd_produto', sortable: false },
          { align: 'center', text: 'Descrição', value: 'code', sortable: false },
          { align: 'center', text: 'Forma Pgmt.', value: 'valor_unit', sortable: false },
          { align: 'center', text: 'Opções', value: 'finance.status', sortable: false }
        ]
      }
    },
    methods: {
      getNewFinanceData: function (BusinessPersonFinance) {
        console.log(this.props.item.person)
        return {
          advanced: true,
          date_validity: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0'),
          description: 'Pagamento ',
          hide_in_out: true,
          person_id: this.props.item.person.id,
          person_make: true,
          person_type: 'collaborator',
          status: 1,
          amount_initial: BusinessPersonFinance.amount,
          finance_account_id: BusinessPersonFinance.finance_account_id,
          finance_form_payment_id: BusinessPersonFinance.finance_form_payment_id,
          observation: this.$options.filters.money(BusinessPersonFinance.amount) + ' - Processo #' + this.props.item.business_id,
          business_person_finances: [BusinessPersonFinance.id]
        }
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
.justify-center {
  text-align: center;
}
</style>
